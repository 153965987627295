import React from 'react'
import { useBlockProps } from '@wordpress/block-editor'

import { useState } from '@wordpress/element'
import { View } from '@wordpress/primitives'
import FileUpload from '../../../components/FileUpload'

const audio = {
  apiVersion: 2,
  name: 'medienhaus/audio',
  title: 'Audio',
  description: 'Audio',
  keywords: ['audio'],
  icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" /><path d="M10 7.22L6.603 10H3v4h3.603L10 16.78V7.22zM5.889 16H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3.889l5.294-4.332a.5.5 0 0 1 .817.387v15.89a.5.5 0 0 1-.817.387L5.89 16zm13.517 4.134l-1.416-1.416A8.978 8.978 0 0 0 21 12a8.982 8.982 0 0 0-3.304-6.968l1.42-1.42A10.976 10.976 0 0 1 23 12c0 3.223-1.386 6.122-3.594 8.134zm-3.543-3.543l-1.422-1.422A3.993 3.993 0 0 0 16 12c0-1.43-.75-2.685-1.88-3.392l1.439-1.439A5.991 5.991 0 0 1 18 12c0 1.842-.83 3.49-2.137 4.591z" fill="var(--color-fg)" /></svg>,
  attributes: {
    url: {
      type: 'string'
    }
  },
  edit: (props) => {
    const {
      attributes: { url },
      setAttributes,
      onRemove
    } = props
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const blockProps = useBlockProps()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [file, setFile] = useState()

    if (url) {
      return (
        <View {...blockProps}>
          <audio controls style={{ width: '100%' }}><source src={url} /></audio>
        </View>
      )
    }

    if (file) {
      return (
        <View {...blockProps}>
          <audio controls style={{ width: '100%' }}><source src={URL.createObjectURL(file)} /></audio>
        </View>
      )
    }

    const handleFormSubmission = (e, selectedFile, fileName, author, license, alttext) => {
      setFile(selectedFile)
      setAttributes({ file: selectedFile, author, license, alttext })
    }

    return (
      <View {...blockProps}>
        <FileUpload
          callback={onRemove}
          fileType="audio"
          handleSubmission={handleFormSubmission}
        />
      </View>
    )
  }
}

export default audio
