import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Matrix from '../../../Matrix'
import { Loading } from '../../../components/loading'
import LoadingSpinnerButton from '../../../components/LoadingSpinnerButton'
import { useTranslation } from 'react-i18next'

import config from '../../../config.json'
import _ from 'lodash'

const ProjectTitle = ({ title, projectSpace, template, callback }) => {
  const { t } = useTranslation('content')
  const [projectTitle, setProjectTitle] = useState('')
  const [edit, setEdit] = useState(false)
  const [newProject, setNewProject] = useState(false)
  const [oldTitle, setOldTitle] = useState('')
  const [loading, setLoading] = useState(false)
  const matrixClient = Matrix.getMatrixClient()
  const history = useHistory()

  useEffect(() => {
    setProjectTitle(title)
    title === '' ? setNewProject(true) : setNewProject(false)
  }, [title])

  const createProject = useCallback(async (title) => {
    setLoading(true)

    const opts = (template, name, history) => {
      return {
        preset: 'private_chat',
        name: name,
        room_version: '9',
        creation_content: { type: 'm.space' },
        initial_state: [{
          type: 'm.room.history_visibility',
          content: { history_visibility: history }
        }, //  world_readable
        {
          type: 'dev.medienhaus.meta',
          content: {
            version: '0.4',
            type: 'item',
            template: template,
            application: process.env.REACT_APP_APP_NAME,
            published: 'draft'
          }
        },
        {
          type: 'm.room.guest_access',
          state_key: '',
          content: { guest_access: 'can_join' }
        }],
        power_level_content_override: {
          ban: 50,
          events: {
            'm.room.avatar': 50,
            'm.room.canonical_alias': 50,
            'm.room.encryption': 100,
            'm.room.history_visibility': 100,
            'm.room.name': 50,
            'm.room.power_levels': 100,
            'm.room.server_acl': 100,
            'm.room.tombstone': 100,
            'm.space.child': 50,
            'm.room.topic': 50,
            'm.room.pinned_events': 50,
            'm.reaction': 50,
            'im.vector.modular.widgets': 50
          },
          events_default: 50,
          historical: 100,
          invite: 50,
          kick: 50,
          redact: 50,
          state_default: 50,
          users_default: 0
        },
        visibility: 'private'
      }
    }
    try {
      // create the project space for the student project
      await matrixClient.createRoom(opts(template, title, 'world_readable'))
        .then(async (space) => {
          // by default we create subpsaces for localisation and one for events
          for await (const lang of config.medienhaus?.languages) {
            const languageRoom = await matrixClient.createRoom(opts('lang', lang, 'shared'))
            await Matrix.addSpaceChild(space.room_id, languageRoom.room_id)
          }
          // const events = await matrixClient.createRoom(opts('events', 'events', 'shared'))
          return space.room_id
        })
        .then(async (itemSpaceRoomId) => {
          // add this project to our application -> cms space
          await Matrix.addSpaceChild(localStorage.getItem(process.env.REACT_APP_APP_NAME + '_space'), itemSpaceRoomId)
          return itemSpaceRoomId
        })
        .then((itemSpaceRoomId) => history.push(`/create/${itemSpaceRoomId}`))
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [history, matrixClient, template])

  const onClickCreateNewProject = useCallback((e) => {
    if (newProject && projectTitle.length < 101) {
      createProject(projectTitle)
      setOldTitle(projectTitle)
      setNewProject(false)
    } else {
      if (e) e.preventDefault()
      setNewProject(true)
    }
  }, [createProject, newProject, projectTitle])

  return (
    <>
      <div className="maxlength">
        <input
          id="title"
          maxLength="100"
          name="title"
          type="text"
          value={projectTitle}
          onClick={() => { setEdit(true); setOldTitle(title) }}
          onChange={(e) => { setProjectTitle(e.target.value) }}
          onKeyUp={e => { if (e.key === 'Enter' && newProject) { _.defer(onClickCreateNewProject) } }}
        />
        <span>{projectTitle.length + '/100'}</span>
      </div>
      {/*
      <p>❗️ Please provide just the project title without any year or artist name.</p>
      */}{loading
      ? <Loading />
      : edit && (projectTitle !== oldTitle) &&
        <div className={!newProject ? 'confirmation' : null}>
          {!newProject && <button className="cancel" onClick={(e) => { e.preventDefault(); setEdit(false); setProjectTitle(oldTitle) }}>{t('CANCEL')}</button>}
          {!title && newProject && <LoadingSpinnerButton disabled={!projectTitle || projectTitle.length > 100} onClick={onClickCreateNewProject}>{t('Create')}</LoadingSpinnerButton>}

          {title && edit && (projectTitle !== oldTitle) &&
            <LoadingSpinnerButton
              className="confirm" disabled={projectTitle.length > 100} onClick={async () => {
                if (projectTitle.length < 101) {
                  try {
                    await matrixClient.setRoomName(projectSpace, projectTitle).then(() => callback(projectTitle))
                  } catch (err) {
                    console.error(err)
                  }
                  setEdit(false)
                } else {
                  setEdit(true)
                  setOldTitle(title)
                }
              }}
            >{t('SAVE')}
            </LoadingSpinnerButton>}
        </div>}
    </>
  )
}
export default ProjectTitle
